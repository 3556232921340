import { DEFAULT_CURRENCY_LOCALE } from "../Constants/General.constant";

export const capitalizeFirstLetter = string => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const currencyFormatter = (locale: string = DEFAULT_CURRENCY_LOCALE) => (
  val: Number,
) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'IDR',
    maximumFractionDigits: 0
  }).format(val as any);
};

/**
 * This function has two roles:
 * 1) If the `id` is empty it assings something so does i18next doesn't throw error. Typescript should prevent this anyway
 * 2) It has a hand-picked name `_t` (to be short) and should only be used while using objects instead of strings for translation keys
 * `internals/extractMessages/stringfyTranslations.js` script converts this to `t('a.b.c')` style before `i18next-scanner` scans the file contents
 * so that our json objects can also be recognized by the scanner.
 */
export const _t = (id: string, ...rest: any[]): [string, ...any[]] => {
    if (!id) {
        id = '_NOT_TRANSLATED_'
    }
    return [id, ...rest]
}
