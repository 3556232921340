import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import Icon from '@ant-design/icons';
import {
  // VscBell,
  VscSignOut,
} from 'react-icons/vsc';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import { useSelector } from 'react-redux';

import { useInjectReducer } from '../../../Utils/Redux.utils';
import programList from '../../../Constants/Features.constant';
import { messages } from '../Sidebar/Sidebar.messages';
import {
  FlagImage,
  LanguageSwitch,
  LanguageSwitchOption,
} from '../../../Containers/Auth/LoginPage/components/styledComponents';
import * as loginSlice from '../../../Containers/Auth/LoginPage/RightSide/slice';
import { selectUserData } from '../../../Containers/Auth/LoginPage/RightSide/slice/selectors';
import EnFlag from '../../../Assets/Images/Country/gbr.svg';
import IdFlag from '../../../Assets/Images/Country/idn.svg';
import { AvatarCircle } from '../../DataDisplay/AvatarCircle';

import { Title, NavbarContainer, MenuContainer } from './Navbar.styles';

interface Props {
  isMobile?: boolean;
  showNavbar?: boolean;
}

// const menu = (
//     <Menu>
//         <Menu.Item>1st menu item</Menu.Item>
//     </Menu>
// )

const Navbar = (props: Props) => {
  const { showNavbar = true } = props;
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t, i18n } = useTranslation();
  const { isMobile } = props;
  
  const { reducer: loginsReducer, sliceKey: loginsSliceKey } = loginSlice;
  useInjectReducer({
    key: loginsSliceKey,
    reducer: loginsReducer,
  });
  const user = useSelector(selectUserData);
  const isMerchantManagerOrAdmin =
    user?.role === 'merchantManager' || user?.role === 'merchantAdmin';

  let program: { name: string; translationId: string } = { name: '', translationId: '' };

  map(programList, prog => {
    if (prog.path === location.pathname) {
      program = prog;
    }
    if (prog.children) {
      map(prog.children, child => {
        if (child.path === location.pathname) program = child;
      });
    }
  });

  const handleChangeLanguage = value => i18n.changeLanguage(value);

  return (
    <>
      {showNavbar && (
        <NavbarContainer>
          <MenuContainer isMobile={isMobile} pathname={location.pathname}>
            <Title>{t(messages[program?.translationId]) as string}</Title>
            <Space size="middle">
              {isMerchantManagerOrAdmin ? 
              <Typography.Text style={{ fontWeight: 600 }}>
                <Typography.Text style={{ fontWeight: 'normal' }}>
                  Merchant:{' '}
                </Typography.Text>
                {user?.merchant?.merchantName}
              </Typography.Text>
              : null}
              {/* <Dropdown overlay={menu} trigger={['click']}> */}
              <AvatarCircle
                size={32}
                //  className="pointer"
              >
                {user?.fullName?.firstName?.charAt(0).toUpperCase()}
                {user?.fullName?.lastName?.charAt(0).toUpperCase()}
              </AvatarCircle>
              {/* </Dropdown> */}
              <Typography.Text style={{ fontWeight: 600 }}>
                <Typography.Text style={{ fontWeight: 'normal' }}>
                  Hi,{' '}
                </Typography.Text>
                {capitalize(user?.fullName?.firstName)}{' '}
                {capitalize(user?.fullName?.lastName)}
              </Typography.Text>
              <LanguageSwitch
                defaultValue={i18n.language}
                size="large"
                onChange={handleChangeLanguage}
                bordered={false}
                showArrow={false}
                style={{
                  width: 67,
                }}
              >
                <LanguageSwitchOption value="en">
                  <FlagImage src={EnFlag} /> EN
                </LanguageSwitchOption>
                <LanguageSwitchOption value="id">
                  <FlagImage src={IdFlag} /> ID
                </LanguageSwitchOption>
              </LanguageSwitch>
            </Space>
            <Space size="large">
              {/* <Badge
                                count={5}
                                style={{
                                    backgroundColor: '#52c41a',
                                    boxShadow:
                                        '0 3px 6px -4px rgba(0, 0, 0, 0.22), 0 6px 16px 0 rgba(0, 0, 0, 0.18), 0 9px 28px 8px rgba(0, 0, 0, 0.15)',
                                }}
                            >
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Icon
                                        component={VscBell}
                                        style={{
                                            fontSize: 20,
                                            color: '#aeb9bf',
                                        }}
                                    />
                                </Dropdown>
                            </Badge> */}
              <Icon
                //
                component={VscSignOut}
                onClick={() => {
                  localStorage.removeItem('authToken');
                  localStorage.removeItem('refreshToken');
                  navigate('/login');
                }}
                style={{
                  fontSize: 20,
                  color: '#9396a7',
                }}
              />
            </Space>
          </MenuContainer>
        </NavbarContainer>
      )}
    </>
  );
}

export default Navbar;