import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ContainerState, VerificationsErrorType } from './types';

export const initialState: ContainerState = {
  dataInputJournals: {},
  dataTopups: {},
  dataTransfers: {},
  dataWithdrawals: {},
  inputJournalsTotal: 0,
  topupsTotal: 0,
  transfersTotal: 0,
  withdrawsTotal: 0,
  loading: false,
};

const verificationsPageSlice = createSlice({
  name: 'verificationPage',
  initialState,
  reducers: {
    approveVerification(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    declineVerification(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
    },
    getInputJournals(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      state.dataInputJournals = {};
    },
    getTopups(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      state.dataTopups = {};
    },
    getTransfers(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      state.dataTransfers = {};
    },
    getWithdrawals(state, action: PayloadAction<any>) {
      state.loading = true;
      state.error = null;
      state.dataWithdrawals = {};
    },
    verificationsLoaded(state, action: PayloadAction<any>) {
      let data = {
        dataInputJournals: state.dataInputJournals,
        dataTopups: state.dataTopups,
        dataTransfers: state.dataTransfers,
        dataWithdrawals: state.dataWithdrawals,
        inputJournalsTotal: state.inputJournalsTotal,
        topupsTotal: state.topupsTotal,
        transfersTotal: state.transfersTotal,
        withdrawsTotal: state.withdrawsTotal,
      };

      if (action.payload.type === 'input_journal') {
        data.dataInputJournals = action.payload.verifications;
        data.inputJournalsTotal = action.payload.total;
      }
      if (action.payload.type === 'topup') {
        data.dataTopups = action.payload.verifications;
        data.topupsTotal = action.payload.total;
      }
      if (action.payload.type === 'transfer') {
        data.dataTransfers = action.payload.verifications;
        data.transfersTotal = action.payload.total;
      }
      if (action.payload.type === 'withdraw') {
        data.dataWithdrawals = action.payload.verifications;
        data.withdrawsTotal = action.payload.total;
      }

      return {
        ...state,
        ...data,
        loading: false,
      };
    },
    verificationsLoadedWithoutData(state) {
      return {
        ...state,
        loading: false,
      };
    },
    verificationsError(state, action: PayloadAction<VerificationsErrorType>) {
      state.error = action.payload;
      state.loading = false;
    },
    export(state, payload) {
      // state.loading = true
      // state.error = null
      // state.data = action.payload
    },
  },
});

export const { actions, reducer, name: sliceKey } = verificationsPageSlice;
