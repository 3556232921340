import Layout from 'antd/lib/layout';
import styled from 'styled-components';

// const StyledLink = styled.a`
//     font-size: 16px;
//     font-weight: 600;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `

export const StyledDiv = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 60%;

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
`;

export const StyledLayout = styled(Layout)`
  position: fixed;
  background: #fcfcfc;
  width: 300px;
  // box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
  //     0 10px 10px -5px rgba(0, 0, 0, 0.04);

  .ant-badge {
    font-size: unset;
    display: flex;
    align-self: center;
  }

  .ant-badge-status-text {
    align-self: center;
  }
`;

export const StyledSider = styled(Layout.Sider)`
  background: #fff !important;
`;

export const MenuTitle = styled.span`
  font-weight: 500;
  cursor: pointer;
  margin-left: 2px;
  font-size: 15px;
  color: #2d3748;
  text-align: center;
`;
