import React from 'react'

import { HiOutlineHome } from 'react-icons/hi'
import {
    //  BiChalkboard,
    //  BiDoorOpen,
    BiStore,
    BiTransfer,
} from 'react-icons/bi'
import { FiTruck } from 'react-icons/fi'
import {
    AiFillAppstore,
    //  AiFillSetting,
    AiOutlineCheck,
} from 'react-icons/ai'
import { FiUsers, FiBox } from 'react-icons/fi'
import {
  // FaWpforms,
  FaBalanceScale,
  FaMoneyBill,
  FaHandHoldingUsd,
} from 'react-icons/fa';
import { GiPayMoney, GiReceiveMoney } from 'react-icons/gi'
import { GrMoney, GrHistory } from 'react-icons/gr'
import {
    MdSettingsInputComponent,
    // MdPayment,
    MdAccountBalanceWallet,
    // MdSchool,
} from 'react-icons/md'
import { IoHomeSharp, IoBookSharp } from 'react-icons/io5'
import { RiMoneyDollarCircleFill } from 'react-icons/ri'
// import { FaUsers } from 'react-icons/fa'
import SettingOutlined from '@ant-design/icons/SettingOutlined'
import { GrReturn } from 'react-icons/gr';

import {  CustomIcon } from '../Components/Commons/CustomIcon'

export const programListMobile = [
    {
        path: '/',
        name: 'Dashboard',
        icon: <CustomIcon component={IoHomeSharp} />,
    },
    {
        path: '/',
        name: 'Manajemen',
        icon: <CustomIcon component={MdSettingsInputComponent} />,
    },
    {
        path: '/',
        name: 'Akademis',
        icon: <CustomIcon component={IoBookSharp} />,
    },
    {
        path: '/',
        name: 'Finansial',
        icon: <CustomIcon component={RiMoneyDollarCircleFill} />,
    },
    {
        path: '/',
        name: 'Semua Menu',
        icon: <CustomIcon component={AiFillAppstore} />,
    },
    {
        path: '/',
        name: 'Settings',
        icon: <SettingOutlined  />,
    },
]

const programList = [
  {
    path: '/',
    name: 'Dashboard',
    translationId: 'dashboard',
    icon: <CustomIcon component={HiOutlineHome} />,
    roles: [
      'superAdmin',
      'superAdminFinance',
      'institutionAdmin',
      'institutionFinance',
      'merchantManager',
      'merchantAdmin',
    ],
  },
  // {
  //     name: 'Pendaftaran',
  //     translationId: 'admission',
  //     icon: <AntdCustomIcon component={FaWpforms} />,
  //     children: [
  //         {
  //             path: '/registration/selection',
  //             name: 'Seleksi',
  //             icon: <AntdCustomIcon component={FaUsers} />,
  //             translationId: 'selection',
  //             parent: 'Pendaftaran',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '/registration/payment',
  //             name: 'Pembayaran',
  //             icon: <AntdCustomIcon component={MdPayment} />,
  //             translationId: 'payment',
  //             parent: 'Pendaftaran',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '/registration/settings',
  //             name: 'Pengaturan',
  //             icon: <AntdCustomIcon component={AiFillSetting} />,
  //             translationId: 'settings',
  //             parent: 'Pendaftaran',
  //             roles: ['superAdmin'],
  //         },
  //     ],
  // },
  {
    name: 'Saldo',
    translationId: 'saldo',
    icon: <CustomIcon component={MdAccountBalanceWallet} />,
    children: [
      // {
      //     path: '/saldo/create-institution',
      //     name: 'Create Institution',
      //     icon: <AntdCustomIcon component={MdSchool} />,
      //     translationId: 'createInstitution',
      //     parent: 'Saldo',
      //     roles: ['superAdmin', 'institutionAdmin'],
      // },
      // {
      //     path: '/saldo/balance-settings',
      //     name: 'Balance Settings',
      //     icon: <AntdCustomIcon component={SettingOutlined} />,
      //     translationId: 'balanceSettings',
      //     parent: 'Saldo',
      //     roles: ['superAdmin', 'institutionAdmin'],
      // },
      {
        path: '/saldo/verifications',
        name: 'Verifications',
        icon: <CustomIcon component={AiOutlineCheck} />,
        translationId: 'verifications',
        parent: 'Saldo',
        roles: ['superAdmin', 'superAdminFinance'],
      },
      {
        path: '/saldo/transaction-limits',
        name: 'Transaction Limits',
        icon: <SettingOutlined />,
        translationId: 'transactionLimits',
        parent: 'Saldo',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
      {
        path: '/saldo/student-transaction-limits',
        name: 'Student Transaction Limits',
        icon: <SettingOutlined />,
        translationId: 'studentTransactionLimits',
        parent: 'Saldo',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
      {
        path: '/saldo/student-histories',
        name: 'Student Histories',
        icon: <CustomIcon component={GrHistory} />,
        translationId: 'studentHistories',
        parent: 'Saldo',
        roles: ['superAdmin', 'superAdminFinance'],
      },
    ],
  },
  {
    name: 'Topup',
    translationId: 'topupTitle',
    icon: <CustomIcon component={GrMoney} />,
    children: [
      {
        path: '/saldo/input-topup-journal',
        name: 'Input Jurnal Topup',
        icon: <CustomIcon component={GiReceiveMoney} />,
        translationId: 'inputJurnalTopup',
        parent: 'Topup',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
      {
        path: '/saldo/topup',
        name: 'Topup',
        icon: <CustomIcon component={BiTransfer} />,
        translationId: 'topup',
        parent: 'Topup',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
      {
        path: '/saldo/transfer',
        name: 'Transfer',
        icon: <CustomIcon component={BiTransfer} />,
        translationId: 'transfer',
        parent: 'Topup',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
    ],
  },
  {
    name: 'Balance',
    translationId: 'balance',
    icon: <CustomIcon component={FaBalanceScale} />,
    children: [
      {
        path: '/saldo/withdraw',
        name: 'Withdraw Balance',
        icon: <CustomIcon component={GiPayMoney} />,
        translationId: 'withdraw',
        parent: 'Balance',
        roles: ['superAdmin', 'superAdminFinance'],
      },
      {
        path: '/saldo/merchant-withdraw',
        name: 'Merchant Withdraw',
        icon: <CustomIcon component={GiPayMoney} />,
        translationId: 'merchantWithdraw',
        parent: 'Balance',
        roles: ['superAdmin', 'merchantManager'],
      },
      {
        path: '/saldo/check-balance',
        name: 'Check Balance',
        icon: <CustomIcon component={FaMoneyBill} />,
        translationId: 'checkBalance',
        parent: 'Balance',
        roles: ['superAdmin', 'superAdminFinance', 'institutionFinance'],
      },
      {
        path: '/saldo/credit',
        name: 'Credit',
        icon: <CustomIcon component={FaHandHoldingUsd} />,
        translationId: 'credit',
        parent: 'Balance',
        roles: ['superAdmin', 'merchantManager'],
      },
    ],
  },
  {
    name: 'Merchant',
    translationId: 'merchant',
    icon: <CustomIcon component={BiStore} />,
    children: [
      {
        path: '/saldo/suppliers',
        name: 'Suppliers Management',
        icon: <CustomIcon component={FiTruck} />,
        translationId: 'suppliers',
        parent: 'Merchant',
        roles: ['superAdmin', 'merchantManager'],
      },
      {
        path: '/saldo/transaction-types',
        name: 'Item Category',
        icon: <SettingOutlined />,
        translationId: 'transactionTypes',
        parent: 'Merchant',
        roles: ['superAdmin', 'merchantManager'],
      },
      {
        path: '/saldo/inventory',
        name: 'Inventory Management',
        icon: <CustomIcon component={FiBox} />,
        translationId: 'inventory',
        parent: 'Merchant',
        roles: ['superAdmin', 'merchantAdmin', 'merchantManager'],
      },
      {
        path: '/saldo/transaction-histories',
        name: 'Transaction Histories',
        icon: <CustomIcon component={GrHistory} />,
        translationId: 'transactionHistories',
        parent: 'Merchant',
        roles: ['superAdmin', 'merchantAdmin', 'merchantManager'],
      },
      {
        path: '/saldo/transaction-refunds',
        name: 'Transaction Refunds',
        icon: <CustomIcon component={GrReturn} />,
        translationId: 'transactionRefunds',
        parent: 'Merchant',
        roles: ['superAdmin'],
      },
    ],
  },
  // {
  //     name: 'Akademis',
  //     translationId: 'academic',
  //     icon: <AntdCustomIcon component={IoBookSharp} />,
  //     children: [
  //         {
  //             path: '',
  //             name: 'Mata Pelajaran',
  //             icon: <AntdCustomIcon component={BiBook} />,
  //             translationId: 'subject',
  //             parent: 'Akademis',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Jadwal',
  //             icon: <AntdCustomIcon component={AiOutlineCalendar} />,
  //             translationId: 'schedule',
  //             parent: 'Akademis',
  //         },
  //         {
  //             path: '',
  //             name: 'Scores',
  //             icon: <AntdCustomIcon component={GrScorecard} />,
  //             translationId: 'grade',
  //             parent: 'Akademis',
  //             roles: ['superAdmin'],
  //         },
  //     ],
  // },
  // {
  //     name: 'Manajemen',
  //     icon: <AntdCustomIcon component={MdSettingsInputComponent} />,
  //     translationId: 'management',
  //     children: [
  //         {
  //             path: '',
  //             name: 'Absen',
  //             icon: <AntdCustomIcon component={IoHandRightOutline} />,
  //             translationId: 'absence',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Ijin Pergi',
  //             icon: <AntdCustomIcon component={AiOutlineSchedule} />,
  //             translationId: 'leave',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Kesehatan',
  //             icon: <AntdCustomIcon component={GiHealthNormal} />,
  //             translationId: 'health',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Achievement',
  //             icon: <AntdCustomIcon component={GrAchievement} />,
  //             translationId: 'achievement',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Pelanggaran',
  //             icon: <AntdCustomIcon component={TiWarningOutline} />,
  //             translationId: 'violant',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //         {
  //             path: '',
  //             name: 'Organization',
  //             icon: <AntdCustomIcon component={VscOrganization} />,
  //             translationId: 'organization',
  //             parent: 'Manajemen',
  //             roles: ['superAdmin'],
  //         },
  //     ],
  // },
  // {
  //     path: '',
  //     name: 'Invoice',
  //     icon: <AntdCustomIcon component={FaFileInvoiceDollar} />,
  //     translationId: 'invoice',
  //     roles: ['superAdmin'],
  // },
  {
    name: 'Settings',
    translationId: 'settings',
    icon: <SettingOutlined />,
    children: [
      // {
      //     path: '/settings/rooms',
      //     name: 'Rooms',
      //     icon: <AntdCustomIcon component={BiDoorOpen} />,
      //     translationId: 'rooms',
      //     parent: 'Settings',
      //     roles: ['superAdmin'],
      // },
      // {
      //     path: '/settings/classes',
      //     name: 'Classes',
      //     icon: <AntdCustomIcon component={BiChalkboard} />,
      //     translationId: 'classes',
      //     parent: 'Settings',
      //     roles: ['superAdmin'],
      // },
      {
        path: '/settings/merchants',
        name: 'Merchants',
        icon: <CustomIcon component={BiStore} />,
        translationId: 'merchants',
        parent: 'Settings',
        roles: ['superAdmin'],
      },
      {
        path: '/settings/merchant-users',
        name: 'Merchant Users',
        icon: <CustomIcon component={FiUsers} />,
        translationId: 'merchantUsers',
        parent: 'Settings',
        roles: ['superAdmin'],
      },
      {
        path: '/settings/users',
        name: 'Users',
        icon: <CustomIcon component={FiUsers} />,
        translationId: 'users',
        parent: 'Settings',
        roles: ['superAdmin', 'superAdminFinance', 'institutionAdmin'],
      },
    ],
  },
];

export default programList
