export const MAX_AGE = 60 * 60 * 8 // 8 hours
export const DEFAULT_PAGINATION_CONFIG = {
    defaultCurrent: 1,
    defaultPageSize: 20,
    pageSizeOptions: ['20', '50', '75', '100', '500'],
}
export const YEAR_FORMAT = 'YYYY'
export const MONTH_FORMAT = 'MMMM'
export const MONTH_HALF_FORMAT = 'MMM'
export const DAY_FORMAT = 'DD'
export const WEEK_FORMAT = 'W'
export const DAY_STRING_FORMAT = 'dddd'
export const HOUR_FORMAT = 'HH:mm:ss'
export const YEAR_DATE_HOUR_FULL_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const YEAR_DATE_HOUR_FORMAT = 'YYYY-MM-DD HH:mm'
export const YEAR_DATE_FORMAT = 'YYYY-MM-DD'
export const MONTH_DAY_FORMAT = 'MM-DD'

export const ROOM_TYPES = [
    { label: 'Kelas', value: 'class' },
    { label: 'Aula', value: 'hall' },
    { label: 'Asrama', value: 'dorm' },
    { label: 'Tambahan', value: 'extra' },
]

export const GRADE_TYPES = [
    { label: 'Tsanawiyyah', value: 'Tsanawiyyah' },
    { label: 'Aliyah', value: 'Aliyah' },
    { label: 'MDA', value: 'MDA' },
]

export const ABSENCE_TYPES = [
    { label: 'Kelas', value: 'class' },
    { label: 'Harian', value: 'daily' },
]

export const ABSENCE_TIMES = [
    { label: 'Pagi', value: 'day' },
    { label: 'Malam', value: 'night' },
]

export const NOTIFICATION_MESSAGE_DURATION = 3;

export const DEFAULT_CURRENCY_LOCALE = 'id-ID';

export const AMOUNTS = [50000, 100000, 150000, 200000, 250000, 300000];