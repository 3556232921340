import * as React from 'react';

import { AvatarProps } from 'antd/lib/avatar/avatar';
import Avatar from 'antd/lib/avatar';
import UserOutlined from '@ant-design/icons/UserOutlined';

const AvatarCircle = (props?: AvatarProps) => {
  return (
    <Avatar icon={!props?.children ? <UserOutlined /> : null} {...props}>
      {props?.children}
    </Avatar>
  );
};

export default AvatarCircle;
