export const downloadDocument = async (fileUrl, fileName) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.target = '_blank';
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const isUserAuthenticated = () => localStorage.getItem('authToken');
