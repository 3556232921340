import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ContainerState } from './types'

// The initial state of the LoginPage container
export const initialState: ContainerState = {
    data: {},
    loading: false,
    userData: {},
}

const loginPageSlice = createSlice({
    name: 'loginPage',
    initialState,
    reducers: {
        login(state, action: PayloadAction<any>) {
            state.loading = true
            state.error = null
            state.userData = {}
        },
        loginSuccess(state, action: PayloadAction<any>) {
            state.loading = false
            state.error = null
            state.userData = action.payload
        },
        loginError(state, action: PayloadAction<any>) {
            state.error = action.payload
            state.loading = false
            state.userData = {}
        },
    },
})

export const { actions, reducer, name: sliceKey } = loginPageSlice
