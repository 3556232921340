import React from 'react';

import { Navbar } from '../Navbar';
import { Sidebar } from '../Sidebar';
import { MobileNavigation } from '../../MobileNavigation';

import { Props } from './PageWrapper.types';
import { ContentWrapper } from './PageWrapper.styles';

const PageWrapper: React.FC<Props> = (props: Props) => {
  const {
    children,
    showSidebar,
    showNavbar,
    showNavigation,
    sidebarProps,
    navbarProps,
    navigationprops,
  } = props;

  return (
    <>
      <Sidebar showSidebar={showSidebar} {...sidebarProps} />
      <Navbar showNavbar={showNavbar} {...navbarProps} />
      <ContentWrapper {...props}>{children}</ContentWrapper>
      <MobileNavigation showNavigation={showNavigation} {...navigationprops} />
    </>
  );
};

export default PageWrapper;
