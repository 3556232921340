const typography = {
    fontFamily: 'Poppins',
    fontWeightBold: 500,
    fontSize: 16,
    caption: {
        fontSize: 14,
    },
}

export default typography
