export class ResponseError extends Error {
    public response: Response

    constructor(response: Response) {
        super(response.statusText)
        this.response = response
    }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: any) {
    if (response.status === 204 || response.status === 205) {
        return null
    }
    return response
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = async (response: Response, options: any) => {
    if (response.status >= 200 && response.status < 300) {
        if (options.isBlob) return response.blob()
        return response.json()
    }
    const error = new ResponseError(response)
    let errResponse
    if (options.isBlob) await response.blob().then(res => (errResponse = res))
    else await response.json().then(res => (errResponse = res))
    error.response = errResponse
    throw error
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
    url: string,
    options?: RequestInit | any,
): Promise<{} | { err: ResponseError }> {
    const fetchResponse = await fetch(url, options)
    const response = checkStatus(fetchResponse, options)
    if (options?.dontParse) return response
    return parseJSON(response)
}

// @: https://stackoverflow.com/a/1714899
export const serialize = (obj: object) => {
    var str: string[] = []
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
    return str.join('&')
}
