/**
 * This file is only need if you want to extract messages into JSON files in locales folder
 * AND if you are also using the object syntax instead of string syntax. \
 * Check the documentation section i18n for details
 */
import { translations } from '../../../Locales/translations';
import { _t } from '../../../Utils/String.utils';

export const messages = {
  dashboard: _t(translations.component.sidebar.dashboard, 'Dashboard'),
  admission: _t(translations.component.sidebar.admission.title, 'Admission'),
  selection: _t(
    translations.component.sidebar.admission.selection,
    'Selection',
  ),
  payment: _t(translations.component.sidebar.admission.payment, 'Payment'),
  settings: _t(translations.component.sidebar.admission.settings, 'Settings'),
  academic: _t(translations.component.sidebar.academic.title, 'Academics'),
  classes: _t(translations.component.sidebar.settings.classes, 'Classes'),
  subject: _t(translations.component.sidebar.academic.subject, 'Subjects'),
  schedule: _t(translations.component.sidebar.academic.schedule, 'Schedules'),
  grade: _t(translations.component.sidebar.academic.grade, 'Scores'),
  management: _t(translations.component.sidebar.management.title, 'Management'),
  rooms: _t(translations.component.sidebar.settings.rooms, 'Rooms'),
  absence: _t(translations.component.sidebar.management.absence, 'Attendances'),
  leave: _t(translations.component.sidebar.management.leave, 'Leave Permits'),
  health: _t(
    translations.component.sidebar.management.health,
    'Health Reports',
  ),
  achievement: _t(
    translations.component.sidebar.management.achievement,
    'Achievements',
  ),
  violant: _t(translations.component.sidebar.management.violant, 'Violant'),
  organization: _t(
    translations.component.sidebar.management.organization,
    'Organizations',
  ),
  invoice: _t(translations.component.sidebar.invoice, 'Invoices'),
  users: _t(translations.component.sidebar.settings.users, 'Users'),
  merchants: _t(translations.component.sidebar.settings.merchants, 'Merchants'),
  merchantUsers: _t(
    translations.component.sidebar.settings.merchantUsers,
    'Merchant Users',
  ),
  saldo: _t(translations.component.sidebar.saldo.title, 'Saldo'),
  createInstitution: _t(
    translations.component.sidebar.saldo.createInstitution,
    'Create Institution',
  ),
  balanceSettings: _t(
    translations.component.sidebar.saldo.balanceSettings,
    'Balance Settings',
  ),
  verifications: _t(
    translations.component.sidebar.saldo.verifications,
    'Verifications',
  ),
  transactionTypes: _t(
    translations.component.sidebar.merchant.transactionTypes,
    'Item Category',
  ),
  transactionLimits: _t(
    translations.component.sidebar.saldo.transactionLimits,
    'Transaction Limits',
  ),
  studentTransactionLimits: _t(
    translations.component.sidebar.saldo.studentTransactionLimits,
    'Student Transaction Limits',
  ),
  studentHistories: _t(
    translations.component.sidebar.saldo.studentHistories,
    'Student Histories',
  ),

  topupTitle: _t(translations.component.sidebar.topup.title, 'Topup'),
  inputJurnalTopup: _t(
    translations.component.sidebar.topup.inputJurnalTopup,
    'Input Jurnal Topup',
  ),
  topup: _t(translations.component.sidebar.topup.topup, 'Topup'),
  transfer: _t(translations.component.sidebar.topup.transfer, 'Transfer'),

  balance: _t(translations.component.sidebar.balance.title, 'Balance'),
  withdraw: _t(
    translations.component.sidebar.balance.withdraw,
    'Withdraw Balance',
  ),
  merchantWithdraw: _t(
    translations.component.sidebar.balance.merchantWithdraw,
    'Merchant Withdraw',
  ),
  checkBalance: _t(
    translations.component.sidebar.balance.checkBalance,
    'Check Balance',
  ),
  credit: _t(translations.component.sidebar.balance.credit, 'Credit'),
  transactionRefunds: _t(
    translations.component.sidebar.balance.transactionRefunds,
    'Transaction Refunds',
  ),

  merchant: _t(translations.component.sidebar.merchant.title, 'Merhcant'),
  transactionHistories: _t(
    translations.component.sidebar.merchant.transactionHistories,
    'Transaction Histories',
  ),
  inventory: _t(
    translations.component.sidebar.merchant.inventory,
    'Inventory Management',
  ),
  suppliers: _t(
    translations.component.sidebar.merchant.suppliers,
    'Suppliers Management',
  ),
};
