import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../../../Types';

import { initialState } from '.';

const selectDomain = (state: RootState) => state.loginPage || initialState;

export const selectLoginPage = createSelector(
  [selectDomain],
  loginPage => loginPage,
);

export const selectUserData = createSelector(
  [selectDomain],
  loginPage => loginPage.userData,
);
