import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import map from 'lodash/map';

import { programListMobile } from '../../Constants/Features.constant';

import { NavigationWrapper } from './MobileNavigation.styles';
import { Props } from './MobileNavigation.types';

import type { NavigateFunction } from 'react-router-dom';

const handleRedirect = (path: string, navigate: NavigateFunction) => () => {
  navigate(path);
};

const MobileNavigation: React.FC<Props> = (props: Props) => {
  const { showNavigation } = props;
  const navigate = useNavigate();

  return (
    <>
      {showNavigation && (
        <NavigationWrapper>
          <Menu mode="horizontal">
            {map(programListMobile, (program, index) => (
              <Menu.Item
                key={index}
                icon={program.icon}
                onClick={handleRedirect(program.path, navigate)}
              />
            ))}
          </Menu>
        </NavigationWrapper>
      )}
    </>
  );
};

export default MobileNavigation;
