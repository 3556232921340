import moment from 'moment'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { call, put, takeLatest, delay } from 'redux-saga/effects';

import { request } from '../../../../Utils/API.utils';
import { error, success } from '../../../../Utils/Dialog.utils';
import { downloadDocument } from '../../../../Utils/General.utils';

import { VerificationsErrorType } from './types';

import { actions } from '.';

export const getPendingRequestedThunk = createAsyncThunk(
  'verification/pending-requested',
  async (body: any) => {
    try {
      let token = localStorage.getItem('authToken');
      const requestURL = `${process.env.REACT_APP_BACKEND_API_URL}/verifications/pending-requested`;
      const result = await request(requestURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(body),
      });

      // success('Permintaan berhasil disetujui')

      return result;
    } catch (err: any) {
      const errMsg = err.message;
      error(errMsg);
    }
  },
);

export const approveVerificationThunk = createAsyncThunk(
  'verification/approve',
  async (body: any) => {
    try {
      let token = localStorage.getItem('authToken');
      const { id, ...restBody } = body;
      const requestURL = `${process.env.REACT_APP_BACKEND_API_URL}/verifications/approve/${id}`;
      const result = await request(requestURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(restBody),
      });

      success('Permintaan berhasil disetujui');

      return result;
    } catch (err: any) {
      const errMsg = err.response.message;
      error(errMsg);
    }
  },
);

export const declineVerificationThunk = createAsyncThunk(
  'verification/decline',
  async (body: any) => {
    try {
      let token = localStorage.getItem('authToken');
      const { id, ...restBody } = body;
      const requestURL = `${process.env.REACT_APP_BACKEND_API_URL}/verifications/decline/${id}`;

      const result = await request(requestURL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(restBody),
      });

      success('Permintaan berhasil ditolak');

      return result;
    } catch (err: any) {
      const errMsg = err.response.message;
      error(errMsg);
    }
  },
);

export function* getVerifications(action: any) {
  yield delay(500);
  let token = localStorage.getItem('authToken');
  const requestURL = `${process.env.REACT_APP_BACKEND_API_URL}/verifications/search`;

  try {
    const verifications: any = yield call(request, requestURL, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(action.payload),
      method: 'POST',
    });
    if (verifications) {
      yield put(
        actions.verificationsLoaded({
          verifications: verifications.verifications,
          total: verifications.total,
          type: action.payload.filter.type,
        }),
      );
    } else {
      yield put(
        actions.verificationsError(VerificationsErrorType.RESPONSE_ERROR),
      );
    }
  } catch (err: any) {
    let errMsg = err.response.message;

    if (err.response?.status === 404) {
      yield put(
        actions.verificationsError(VerificationsErrorType.RESPONSE_ERROR),
      );
    }

    yield error(errMsg);
  }
}

export function* exportVerifications(action) {
    yield delay(500)
    const body = action.payload
    let token = localStorage.getItem('authToken')
    const requestURL = `${process.env.REACT_APP_BACKEND_API_URL}/export/verifications`

    try {
        let succMsg = 'Data berhasil didapat'

        const resp = yield call(request, requestURL, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
            dontParse: true,
            isBlob: true,
        })

        if (resp) {
            const href = window.URL.createObjectURL(resp)
            downloadDocument(
                href,
                `verifications_${body.filter.type}_${moment(body.startDate).format(
                    'YYYY-MM-DD',
                )}-${moment(body.endDate).format('YYYY-MM-DD')}`,
            )
        } else {
            error('Data tidak ditemukan')
            yield put(actions.verificationsError(VerificationsErrorType.DATA_NOT_FOUND))
        }

        yield success(succMsg)
    } catch (err: any) {
        console.log(err)
        let errMsg = err.response.message

        yield error(errMsg)
    }
}

export function* inventoryPageSaga() {
  yield takeLatest(actions.getInputJournals.type, getVerifications);
  yield takeLatest(actions.getTopups.type, getVerifications);
  yield takeLatest(actions.getTransfers.type, getVerifications);
  yield takeLatest(actions.getWithdrawals.type, getVerifications);
  yield takeLatest(actions.export.type, exportVerifications);
}
