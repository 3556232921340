import styled from 'styled-components';

import breakpoint from '../../../Config/Breakpoint.config';

interface DivProps {
  pathname?: string;
  isMobile?: boolean;
}

export const Title = styled.div<DivProps>`
  color: #0b9460;
  margin-bottom: 0;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: 600;

  @media only screen and ${breakpoint.device.xs} {
    display: none;
  }
`;

export const NavbarContainer = styled.div<DivProps>`
  background: #f4f7fe;

  margin-left: 300px;

  padding-top: 30px;
  padding-left: 1.7rem;
  padding-right: 1.7rem;

  margin-bottom: 1.75rem;

  @media only screen and ${breakpoint.device.md} {
    margin-left: 0;
  }
`;

export const MenuContainer = styled.div<DivProps>`
  display: flex;
  justify-content: flex-end;
  height: 57px;
`;
