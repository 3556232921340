const scrollbar = `
  ::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      -webkit-border-radius: 10px;
      background-color: #1dbd81;
  }
  ::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }
  ::-webkit-scrollbar-corner {
      background-color: transparent;
  }
`
export default scrollbar
