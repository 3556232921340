import Row from 'antd/lib/row';
import styled from 'styled-components';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';

import { LoadingIconProps } from './Loading.type';

import type { RowProps } from 'antd/lib/row';

export const LoadingPageContainer = styled(Row)<RowProps>(() => ({
  minHeight: 'calc(100vh - 125px)',
}));

export const LoadingIcon = styled(LoadingOutlined)<LoadingIconProps>(props => ({
  fontSize: props.fontSize,
}));
