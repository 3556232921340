export interface VerificationsPageState {
    loading: boolean
    error?: VerificationsErrorType | null
    dataInputJournals?: any
    dataTopups?: any
    dataTransfers?: any
    dataWithdrawals?: any
    inputJournalsTotal?: any
    topupsTotal?: any
    transfersTotal?: any
    withdrawsTotal?: any
}

export enum VerificationsErrorType {
    RESPONSE_ERROR = 1,
    DATA_NOT_FOUND = 2,
}

export type ContainerState = VerificationsPageState
