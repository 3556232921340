import styled from 'styled-components';
import Row from 'antd/lib/row';

export const NavigationWrapper = styled(Row)`
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 4px 6px 5px rgba(0, 0, 0, 0.1),
    0 2px 4px 5px rgba(0, 0, 0, 0.06);

  .ant-menu-horizontal {
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: space-between;
  }

  .ant-menu-item .ant-menu-item-icon,
  .ant-menu-submenu-title .ant-menu-item-icon,
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    font-size: 22px;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    border-bottom: unset;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border-bottom: unset;
  }
`;
