import React from 'react';

import Spin from 'antd/lib/spin';

import { Props } from './Loading.type';
import { LoadingIcon, LoadingPageContainer } from './Loading.styles';

const Loading: React.FC<Props> = (props: Props) => {
  const { onlySpinner = false, fontSize = 45 } = props;

  return onlySpinner ? (
    <Spin indicator={<LoadingIcon fontSize={fontSize} spin />} />
  ) : (
    <LoadingPageContainer justify="center" align="middle">
      <Spin indicator={<LoadingIcon fontSize={45} spin />} />
    </LoadingPageContainer>
  );
};

Loading.displayName = 'LoadingComponent';

export default Loading;
