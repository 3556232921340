import { BalanceSettingsPage } from '../Containers/Saldo/BalanceSettingsPage/Loadable';
import { CheckBalancePage } from '../Containers/Saldo/CheckBalancePage/Loadable';
import { ClassSettingsPage } from '../Containers/Settings/ClassSettingsPage/Loadable';
import { CreateInstitutionPage } from '../Containers/Saldo/CreateInstitutionPage/Loadable';
import { MerchantSettingsPage } from '../Containers/Settings/MerchantSettingsPage/Loadable';
import { MerchantUserSettingsPage } from '../Containers/Settings/MerchantUserSettingsPage/Loadable';
import { InputJournalTopupPage } from '../Containers/Saldo/InputJournalTopupPage/Loadable';
import { InventoryPage } from '../Containers/Saldo/InventoryPage/Loadable';
import { MerchantWithdrawPage } from '../Containers/Saldo/MerchantWithdrawPage/Loadable';
import { PaymentPage } from '../Containers/Registration/PaymentPage/Loadable';
import { RegistrationSettingPage } from '../Containers/Registration/RegistrationSettingPage/Loadable';
import { RoomSettingsPage } from '../Containers/Settings/RoomSettingsPage/Loadable';
import { SelectionPage } from '../Containers/Registration/SelectionPage/Loadable';
import { SuppliersPage } from '../Containers/Saldo/SuppliersPage/Loadable';
import { StudentHistoriesPage } from '../Containers/Saldo/StudentHistoriesPage/Loadable';
import { TopupPage } from '../Containers/Saldo/TopupPage/Loadable';
import { TransactionHistoriesPage } from '../Containers/Saldo/TransactionHistoriesPage/Loadable';
import { TransactionLimitPage } from '../Containers/Saldo/TransactionLimitPage/Loadable';
import { UserTransactionLimitPage } from '../Containers/Saldo/UserTransactionLimitPage/Loadable';
import { TransactionTypePage } from '../Containers/Saldo/TransactionTypePage/Loadable';
import { TransferPage } from '../Containers/Saldo/TransferPage/Loadable';
import { UserSettingsPage } from '../Containers/Settings/UserSettingsPage/Loadable';
import { VerificationsPage } from '../Containers/Saldo/VerificationsPage/Loadable';
import { WithdrawPage } from '../Containers/Saldo/WithdrawPage/Loadable';
import { CreditPage } from '../Containers/Saldo/CreditPage/Loadable';
import { TransactionRefundsPage } from '../Containers/Saldo/TransactionRefundsPage/Loadable';
import { PageWrapper } from '../Components/Layout/PageWrapper';

const SALDO_ROUTES = [
  {
    path: 'input-topup-journal',
    oldPath: '/saldo/input-topup-journal',
    component: InputJournalTopupPage,
    wrapper: PageWrapper,
  },
  {
    path: 'topup',
    oldPath: '/saldo/topup',
    component: TopupPage,
    wrapper: PageWrapper,
  },
  {
    path: 'transfer',
    oldPath: '/saldo/transfer',
    component: TransferPage,
    wrapper: PageWrapper,
  },
  {
    path: 'create-institution',
    oldPath: '/saldo/create-institution',
    component: CreateInstitutionPage,
    wrapper: PageWrapper,
  },
  {
    path: 'withdraw',
    oldPath: '/saldo/withdraw',
    component: WithdrawPage,
    wrapper: PageWrapper,
  },
  {
    path: 'merchant-withdraw',
    oldPath: '/saldo/merchant-withdraw',
    component: MerchantWithdrawPage,
    wrapper: PageWrapper,
  },
  {
    path: 'credit',
    oldPath: '/saldo/credit',
    component: CreditPage,
    wrapper: PageWrapper,
  },
  {
    path: 'check-balance',
    oldPath: '/saldo/check-balance',
    component: CheckBalancePage,
    wrapper: PageWrapper,
  },
  {
    path: 'transaction-histories',
    oldPath: '/saldo/transaction-histories',
    component: TransactionHistoriesPage,
    wrapper: PageWrapper,
  },
  {
    path: 'student-histories',
    oldPath: '/saldo/student-histories',
    component: StudentHistoriesPage,
    wrapper: PageWrapper,
  },
  {
    path: 'balance-settings',
    oldPath: '/saldo/balance-settings',
    component: BalanceSettingsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'suppliers',
    oldPath: '/saldo/suppliers',
    component: SuppliersPage,
    wrapper: PageWrapper,
  },
  {
    path: 'inventory',
    oldPath: '/saldo/inventory',
    component: InventoryPage,
    wrapper: PageWrapper,
  },
  {
    path: 'verifications',
    oldPath: '/saldo/verifications',
    component: VerificationsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'transaction-types',
    oldPath: '/saldo/transaction-types',
    component: TransactionTypePage,
    wrapper: PageWrapper,
  },
  {
    path: 'transaction-limits',
    oldPath: '/saldo/transaction-limits',
    component: TransactionLimitPage,
    wrapper: PageWrapper,
  },
  {
    path: 'student-transaction-limits',
    oldPath: '/saldo/student-transaction-limits',
    component: UserTransactionLimitPage,
    wrapper: PageWrapper,
  },
  {
    path: 'transaction-refunds',
    oldPath: '/saldo/transaction-refunds',
    component: TransactionRefundsPage,
    wrapper: PageWrapper,
  },
];

const REGISTRATION_ROUTES = [
  {
    path: 'selection',
    oldPath: '/registration/selection',
    component: SelectionPage,
    wrapper: PageWrapper,
  },
  {
    path: 'payment',
    oldPath: '/registration/payment',
    component: PaymentPage,
    wrapper: PageWrapper,
  },
  {
    path: 'settings',
    oldPath: '/registration/settings',
    component: RegistrationSettingPage,
    wrapper: PageWrapper,
  },
];

const SETTINGS_ROUTES = [
  {
    path: 'rooms',
    oldPath: '/settings/rooms',
    component: RoomSettingsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'classes',
    oldPath: '/settings/classes',
    component: ClassSettingsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'users',
    oldPath: '/settings/users',
    component: UserSettingsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'merchants',
    oldPath: '/settings/merchants',
    component: MerchantSettingsPage,
    wrapper: PageWrapper,
  },
  {
    path: 'merchant-users',
    oldPath: '/settings/merchant-users',
    component: MerchantUserSettingsPage,
    wrapper: PageWrapper,
  },
];

const Constants = {
  SALDO_ROUTES,
  REGISTRATION_ROUTES,
  SETTINGS_ROUTES,
};

export default Constants;
