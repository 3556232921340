import { css } from 'styled-components'

const skeleton = css`
    ${({ theme }) => `
        .anticon {
            display: inline-block;
            vertical-align: middle;
        }

        .ant-picker-separator {
            .anticon {
                display: flex;
                justify-content: center;
                align-items: center;f
            }
        }

        .ant-table-filter-dropdown-btns {
            border-radius: 0 0 10px 10px;
        }

        .ant-btn-primary {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
        }

        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            border-radius: 10px !important; 
        }

        .ant-space-item {
            display: flex; 
            align-items: center;
            justify-content: center;
        }

        .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }

        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 2.1875rem !important;
        }
        .ant-input-affix-wrapper {
            border-radius: 2.1875rem !important;
            > input.ant-input {
                border-radius: 0;
            }
        }
        .ant-form-item-explain, .ant-form-item-extra {
            font-weight: 600;
        }

        .ant-avatar {
            display: flex !important;
            justify-content: center !important;
            align-items: center;
        }

        // @: https://github.com/ant-design/ant-design/issues/16695
        .ant-checkbox-checked .ant-checkbox-inner::after {
            -webkit-transform: rotate(45deg) scale(1) translate(-100%, -20%) !important;
            transform: rotate(45deg) scale(1) translate(-100%, -20%) !important;
        }
        .ant-checkbox-inner::after {
            left: 50% !important;
        }

        .ant-message-notice-content {
            // border-radius: 20px;
            // z-index: 999999999;
        }

        .ant-table-wrapper {
            width: 100%;
        }

        // .ant-table-thead > tr > th {
        //     background: transparent;
        // }

        .ant-table-tbody > tr > td {
            border-bottom: unset;
        }

        .ant-table-thead th.ant-table-column-has-sorters:hover {
            background: transparent;
        }

        // DROPDOWN MENU ITEM BRODER RADIUS
        .ant-dropdown-menu {
            border-radius: 5px;
        }

        // SIDEBAR
        .ant-layout-sider {
            height: 100vh;
            background: white;

            .logo {
                // height: 32px;
                margin: 30px 16px 100px 16px;
                background: rgba(255, 255, 255, 0.3);
                img {
                    // width: 150px;
                    // @: https://stackoverflow.com/questions/3186688/drop-shadow-for-png-image-in-css
                    -webkit-filter: drop-shadow(5px 10px 6px #dbdada);
                    filter: drop-shadow(5px 10px 6px #dbdada);
                    height: 57px;
                    width: 57px;
                    object-fit: cover;
                }
            }

            .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
                background: rgb(237, 252, 244);
                background: -moz-linear-gradient(
                    90deg,
                    rgba(237, 252, 244, 1) 0%,
                    rgba(255, 255, 255, 1) 95%
                );
                background: -webkit-linear-gradient(
                    90deg,
                    rgba(237, 252, 244, 1) 0%,
                    rgba(255, 255, 255, 1) 95%
                );
                background: linear-gradient(
                    90deg,
                    rgba(237, 252, 244, 1) 0%,
                    rgba(255, 255, 255, 1) 95%
                );
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#edfcf4",endColorstr="#ffffff",GradientType=1);
            }

            .ant-menu-vertical .ant-menu-submenu,
            .ant-menu-vertical-left .ant-menu-submenu,
            .ant-menu-vertical-right .ant-menu-submenu,
            .ant-menu-inline .ant-menu-submenu {
                padding-bottom: 0;
            }

            .ant-menu-vertical .ant-menu-item,
            .ant-menu-vertical-left .ant-menu-item,
            .ant-menu-vertical-right .ant-menu-item,
            .ant-menu-inline .ant-menu-item,
            .ant-menu-vertical .ant-menu-submenu-title,
            .ant-menu-vertical-left .ant-menu-submenu-title,
            .ant-menu-vertical-right .ant-menu-submenu-title,
            .ant-menu-inline .ant-menu-submenu-title,
            .ant-menu-inline .ant-menu-item:not(:last-child) {
                margin-bottom: 24px;
            }

            // menu title
            .ant-menu-item .ant-menu-item-icon + span,
            .ant-menu-submenu-title .ant-menu-item-icon + span,
            .ant-menu-item .anticon + span,
            .ant-menu-submenu-title .anticon + span,
            // icon
            .ant-menu-item .ant-menu-item-icon,
            .ant-menu-submenu-title .ant-menu-item-icon,
            .ant-menu-item .anticon,
            .ant-menu-submenu-title .anticon {
                display: inline-block;
                vertical-align: middle;
                font-size: 16px;
                font-weight: 600;
            }

            .ant-menu-inline .ant-menu-selected::after,
            .ant-menu-inline .ant-menu-item-selected::after {
                opacity: 0;
            }
        }

        .anticon {
            display: inline-block;
            vertical-align: middle;
        }
    `}
`
export default skeleton
