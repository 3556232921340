import styled from 'styled-components';

export const ContentWrapper = styled.div<any>`
  height: ${props => props.height || '100%'};
  overflow: ${props => props.overflow};
  padding-right: ${props => props.paddingX || '1.3rem'};
  padding-left: ${props => props.paddingX || '1.3rem'};

  margin-left: ${props => props.ml};
  margin-bottom: ${props => props.mb || '1.25rem'};

  &:after {
    content: '';
    display: block;
    height: 10px;
  }
`;
