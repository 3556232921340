import { createGlobalStyle } from 'styled-components'

import _skeleton from './_skeleton'
import _common from './_common'
import _scrollbar from './_scrollbar'
import _overrideAntd from './_overrideAntd'

export const GlobalStyle = createGlobalStyle`
  ${_common}
  ${_overrideAntd}
  ${_scrollbar}
  ${_skeleton}
`
