import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRoute from './Routers/PrivateRoute.component';
import { NotFoundPage } from './Containers/General/NotFoundPage/Loadable';
import { HomePage } from './Containers/General/HomePage/Loadable';
import { PageWrapper } from './Components/Layout/PageWrapper';
import Constants from './Constants/Routes.constant';
// import { Media, MediaContextProvider } from './components/Media';
import { ForgotPasswordPage } from './Containers/Auth/ForgotPasswordPage/Loadable';
import { LoginPage } from './Containers/Auth/LoginPage/Loadable';
import { GlobalStyle } from './Styles/globalStyles';

const { SALDO_ROUTES, REGISTRATION_ROUTES, SETTINGS_ROUTES } = Constants;

const _getWrapperProps = (isMobile: boolean) => ({
  ...(isMobile ? {} : { ml: '300px' }),
  ...(isMobile ? { overflow: 'scroll' } : {}),
  showNavigation: isMobile,
  showSidebar: !isMobile,
  navbarProps: {
    isMobile,
  },
});

const KarangsuciDashboardApp = () => {
  const { i18n } = useTranslation();
  const isMobile = false;

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - PP.Al Hidayah Karangsuci"
        defaultTitle="PP.Al Hidayah Karangsuci"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Al-Hidayah Karangsuci Dashboard" />
      </Helmet>

      <Routes>
        <Route path="login" Component={LoginPage} />
        <Route path="forgot-password" Component={ForgotPasswordPage} />

        <Route
          path="/"
          Component={(renderProps) => (
            <PrivateRoute
              path="/"
              component={HomePage}
              wrapper={PageWrapper}
              wrapperProps={_getWrapperProps(isMobile)}
              renderProps={renderProps}
            />
          )}
        />

        <Route path="registration">
          {REGISTRATION_ROUTES.map(saldo => (
            <Route
              path={saldo.path}
              Component={(renderProps) => (
                <PrivateRoute
                  path={saldo.oldPath}
                  component={saldo.component}
                  wrapper={saldo.wrapper}
                  wrapperProps={_getWrapperProps(isMobile)}
                  renderProps={renderProps}
                />
              )}
            />
          ))}
          <Route path="*" Component={NotFoundPage} />
        </Route>

        <Route path="settings">
          {SETTINGS_ROUTES.map(saldo => (
            <Route
              path={saldo.path}
              Component={(renderProps) => (
                <PrivateRoute
                  path={saldo.oldPath}
                  component={saldo.component}
                  wrapper={saldo.wrapper}
                  wrapperProps={_getWrapperProps(isMobile)}
                  renderProps={renderProps}
                />
              )}
            />
          ))}
          <Route path="*" Component={NotFoundPage} />
        </Route>

        <Route path="saldo">
          {SALDO_ROUTES.map(saldo => (
            <Route
              path={saldo.path}
              Component={(renderProps) => (
                <PrivateRoute
                  path={saldo.oldPath}
                  component={saldo.component}
                  wrapper={saldo.wrapper}
                  wrapperProps={_getWrapperProps(isMobile)}
                  renderProps={renderProps}
                />
              )}
            />
          ))}
          <Route path="*" Component={NotFoundPage} />
        </Route>

        <Route path="*" Component={NotFoundPage} />

        {/* <MediaContextProvider>
          <Media at="xs">
            <Route Component={() => <AllRoutes isMobile={true} />} />
          </Media>
          <Media at="sm">
            <Route Component={() => <AllRoutes isMobile={true} />} />
          </Media>
          <Media greaterThan="sm">
            <Route Component={() => <AllRoutes isMobile={false} />} />
          </Media>
        </MediaContextProvider> */}
      </Routes>

      <GlobalStyle />
    </BrowserRouter>
  );
};

export default KarangsuciDashboardApp;
