// import { fade } from '@material-ui/core/styles/colorManipulator'

// Main color palette
const primaryColor = '#5FD006'
const karangsuciTextColor = '#283154'
// const secondaryColor = fade(karangsuciTextColor, 0.72)
const selectedColor = '#EAFFDA'
const iconColor = '#5E6681'

// Text color
const primaryTextColor = '#492C4E'

const palette = {
    karangsuci: {
        green: '#5FD006',
        greenTwo: '#27AE60',
        blue: '#2F80ED',
        red: '#EB5757',
        yellow: '#F2C94C',
        white: '#ffffff',
        orange: '#F2994A',
        // whiteOne: fade('#ffffff', 0.48),
        gray: '#AAA5A5',
        textColor: karangsuciTextColor,
        // textColorOne: secondaryColor,
        // textColorThree: fade(karangsuciTextColor, 0.48),
    },
    primary: {
        main: primaryColor,
        selected: selectedColor,
        icon: iconColor,
    },
    // secondary: {
    // main: secondaryColor,
    // },
    error: {
        main: '#FE343B',
    },
    warning: {
        main: '#FFD3D3',
    },
    info: {
        main: '#2F80ED',
    },
    text: {
        primary: primaryTextColor,
        // secondary: fade(primaryTextColor, 0.64),
        // disabled: fade(primaryTextColor, 0.38),
        // hint: fade(primaryTextColor, 0.38),
    },
    background: {
        default: '#F4F7FE ',
        light: '#FAFEFF',
        disabled: '#E0E0E0',
    },
    tooltip: {
        light: '#FFF',
        dark: '#283154',
    },
}

export default palette
