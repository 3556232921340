/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createInjectorsEnhancer } from 'redux-injectors'
import createSagaMiddleware from 'redux-saga'

import { createReducer } from './KarangsuciDashboard.reducers'

function saveToLocalStorage(store) {
    try {
        const serializedStore = JSON.stringify(store)
        window.localStorage.setItem('store', serializedStore)
    } catch (e) {
        console.log(e)
    }
}

function loadFromLocalStorage() {
    try {
        const serializedStore = window.localStorage.getItem('store')
        if (serializedStore === null) return undefined
        return JSON.parse(serializedStore)
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export function configureAppStore() {
    const reduxSagaMonitorOptions = {}
    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions)
    const { run: runSaga } = sagaMiddleware

    // Create the store with saga middleware
    const middlewares = [sagaMiddleware]

    const persistedState = loadFromLocalStorage()

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ]

    const store = configureStore({
        reducer: createReducer(),
        middleware: [
            // @: https://stackoverflow.com/a/64872423
            ...getDefaultMiddleware({ serializableCheck: false }),
            ...middlewares,
        ],
        preloadedState: persistedState,
        devTools: process.env.NODE_ENV !== 'production',
        enhancers,
    })

    store.subscribe(() =>
        saveToLocalStorage({ loginPage: store.getState().loginPage }),
    )

    return store
}
