import React from 'react';

import FontFaceObserver from 'fontfaceobserver';
// import * as ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react'; 

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.less';
import 'react-datepicker/dist/react-datepicker.css';

import './Styles/date-picker.css';
import './Styles/antd-override.less';
import './Locales/i18n';
import serviceWorker from './serviceWorker';
import globalTheme from './Themes';
import { configureAppStore } from './Redux/KarangsuciDashboard.store';
import KarangsuciDashboardApp from './KarangsuciDashboardApp';

// Observe loading of Poppins (to remove 'Poppins', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Poppins', {});

// When Poppins is loaded, add a font-family using Poppins to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
};

const store = configureAppStore();
// const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(document.getElementById('root') as HTMLElement);

// TODO: create composition here to wrap all providers
root.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <Provider store={store}>
        <HelmetProvider>
          <React.StrictMode>
            <ThemeProvider theme={globalTheme}>
              <KarangsuciDashboardApp />
            </ThemeProvider>
          </React.StrictMode>
        </HelmetProvider>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>
  // MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./Locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
