import styled from 'styled-components'
import { Row, Col, Typography, Button, Form, Select } from 'antd'

import breakpoint from '../../../../Config/Breakpoint.config';

export const LoginContainer = styled(Row)`
    background: rgb(237, 242, 247);
    min-height: 100vh !important;
`
export const LeftSideContainer = styled(Col)`
    background: rgb(39, 103, 73);
    box-shadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)';
    position: relative;
    overflow: hidden;
`
export const LeftSideImageBackground = styled.img`
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0.07;
    width: 100%;
`
export const LogoImage = styled.img`
    height: 125px;
    width: 125px;
`
export const FlagImage = styled.img`
    width: 17px;
    height: 13px;
`
export const LanguageSwitchContainer = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`
export const LanguageSwitch = styled(Select)`
    width: 85px;
    float: right;
`
export const LanguageSwitchOption = styled(Select.Option)`
    display: flex;
    align-items: center;
    justify-content: center;
`
export const RightSideContainer = styled(Col)`
    padding: 14vh 100px;
    @media only screen and ${breakpoint.device.md} {
        padding: 8rem 62px;
    }
    @media only screen and ${breakpoint.device.xs} {
        padding: 2rem 62px;
    }
`
export const Title = styled(Typography.Title)`
    &&& {
        font-size: 28px;
        font-weight: 800;
        color: #192a3e;
        margin-bottom: 0.25rem;
        margin-top: 5rem;
        @media only screen and ${breakpoint.device.md} {
            margin-top: 2rem;
        }
    }
`
export const Subtitle = styled(Typography.Text)`
    &&& {
        color: rgb(160, 174, 192);
        margin-top: 0.25rem;
    }
`
export const ContactAdmin = styled(Typography.Text)`
    &&& {
        font-weight: 600;
        top: -1rem;
        position: relative;
        font-size: 0.75rem;
        color: rgb(160, 174, 192);
    }
`
// Form Component
export const LoginForm = styled(Form)`
    &&& {
        margin-top: 2.5rem;
        @media only screen and ${breakpoint.device.md} {
            margin-top: 1rem;
        }
    }
`
export const LoginButton = styled(Button)`
    background: rgb(56, 161, 105) !important;
    box-shadow: rgb(0 0 0 / 10%) 0px 10px 15px -3px,
        rgb(0 0 0 / 5%) 0px 4px 6px -2px !important;
    height: 3rem !important;
    border-radius: 2.1875rem !important;
    margin-top: 2rem !important;
    font-weight: 500 !important;
    color: rgb(255, 255, 255) !important;
    font-size: 1.125rem !important;
    min-width: 3rem !important;
    width: 12.5rem;

    @media only screen and ${breakpoint.device.xs} {
        width: 100%;
    }
`
export const ForgotPassword = styled(Typography.Text)`
    &&& {
        float: right;
        font-size: 0.75rem;
        margin-top: 0.25rem;
        font-weight: 600;
        color: rgb(113, 128, 150);
        cursor: pointer;
    }
`
export const CheckboxFormContainer = styled(Form.Item)`
    &&& {
        @media only screen and ${breakpoint.device.md} {
            margin-bottom: 0;
        }
    }
`
