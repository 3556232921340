import React from 'react';

import Modal, { ModalFuncProps } from 'antd/lib/modal';
import notification, {
  ArgsProps,
} from 'antd/lib/notification';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import startCase from 'lodash/startCase';

import { NOTIFICATION_MESSAGE_DURATION } from '../Constants/General.constant';

const { confirm } = Modal;

export const showDeleteConfirm = (
  handleDelete: (arg?: string | number | undefined) => void,
  id: string | number | undefined,
  params: Partial<ModalFuncProps>,
): void => {
  confirm({
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: async () => Promise.resolve(handleDelete(id)),
    ...params,
  });
};

export const showConfirmDialog = (
  handleOk: () => void,
  params: Partial<ModalFuncProps>,
): void => {
  confirm({
    icon: <ExclamationCircleOutlined />,
    okText: 'Yes',
    cancelText: 'No',
    onOk: async () => Promise.resolve(handleOk()),
    ...params,
  });
};

const triggerNotification = (
  type: 'error' | 'info' | 'success' | 'warning',
  description: ArgsProps['description'] = '[ no description ]',
  message: ArgsProps['message'] = startCase(type),
  duration: ArgsProps['duration'] = NOTIFICATION_MESSAGE_DURATION,
): void => notification[type]({ description, message, duration });

export const error = (description: string): void => triggerNotification('error', description);
export const info = (description: string): void => triggerNotification('info', description);
export const success = (description: string): void => triggerNotification('success', description);
export const warning = (description: string): void => triggerNotification('warning', description);
