import { css } from 'styled-components'

const skeleton = css`
    ${({ theme }) => `
        html, body, #root, #root>div {
            min-height: calc(100vh - calc(100vh - 100%));
        }

        body {
            background: ${theme.colors.background.default} !important;
        }

        body.fontLoaded {
            font-family: ${theme.typography.fontFamily}, 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }

        html {
            box-sizing: border-box;
        }
        *,
        *:before,
        *:after {
            box-sizing: inherit;
        }
        // DISABLE MAPBOX OUTLINE BORDER
        *:focus {
            outline: none;
        }
    `}
`
export default skeleton
