import React from 'react';

import Icon from '@ant-design/icons';

import type { CustomIconProps } from './CustomIcon.type';

const CustomIcon: React.FC<CustomIconProps> = (props: CustomIconProps) => (
  <Icon component={props.component} {...props} />
);

CustomIcon.displayName = 'CustomIconComponent'

export default CustomIcon;
