import React from 'react';

import { Navigate } from 'react-router-dom';
import { find, includes } from 'lodash';
import { useSelector } from 'react-redux';

import { isUserAuthenticated } from '../Utils/General.utils';
import { useInjectReducer } from '../Utils/Redux.utils';
import programList from '../Constants/Features.constant';
import * as loginSlice from '../Containers/Auth/LoginPage/RightSide/slice';
import { selectUserData } from '../Containers/Auth/LoginPage/RightSide/slice/selectors';

export const PrivateRoute = (props: any) => {
  const { reducer: loginsReducer, sliceKey: loginsSliceKey } = loginSlice;
  useInjectReducer({
    key: loginsSliceKey,
    reducer: loginsReducer,
  });
  const user = useSelector(selectUserData);

  let program: any = find(programList, program => {
    if (program.children) {
      return !!find(program.children, children => {
        return (
          props.path.includes(children.path) &&
          children.path !== '/' &&
          children.path !== ''
        );
      });
    }
    return (
      props.path.includes(program.path) &&
      program.path !== '/' &&
      program.path !== ''
    );
  }) || { children: [], path: '', name: '', translationId: '' };
  if (props.path === '/') {
    program = find(programList, program => program.path === '/') || program;
  }

  const acl = program.children
    ? program.children?.find(child => child.path === props.path)
    : program;

  const haveRight = includes(acl?.roles, user?.role);

  // state={{ from: renderProps.location }}
  if (!isUserAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (!!haveRight) {
    return (
      <props.wrapper {...props.wrapperProps}>
        <props.component {...props.renderProps} />
      </props.wrapper>
    );
  } else if (!haveRight) {
    return <Navigate to="/" />;
  }

  return null;
};

export default PrivateRoute;
