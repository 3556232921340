import React from 'react';
import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Menu from 'antd/lib/menu';
import Badge from 'antd/lib/badge';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import find from 'lodash/find';
import { includes } from 'lodash';

import { getPendingRequestedThunk } from '../../../Containers/Saldo/VerificationsPage/slice/saga';
import { useInjectReducer } from '../../../Utils/Redux.utils';
import { selectUserData } from '../../../Containers/Auth/LoginPage/RightSide/slice/selectors';
import * as loginSlice from '../../../Containers/Auth/LoginPage/RightSide/slice';
import programList from '../../../Constants/Features.constant';
import Logo from '../../../Assets/Images/KarangsuciLogo.png';

import { messages } from './Sidebar.messages';
import {
  StyledDiv,
  StyledLayout,
  StyledSider,
  MenuTitle,
} from './Sidebar.styles';

interface Props {
  isMobile?: boolean;
  showSidebar?: boolean;
}

const Sidebar = (props: Props) => {
  const [totalPending, setTotalPending] = useState(0);
  const dispatch = useDispatch<any>();

  const { reducer: loginsReducer, sliceKey: loginsSliceKey } = loginSlice;
  useInjectReducer({
    key: loginsSliceKey,
    reducer: loginsReducer,
  });
  const user = useSelector(selectUserData);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();
  const { showSidebar } = props;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (['superAdmin', 'superAdminFinance'].includes(user.role)) {
      dispatch(getPendingRequestedThunk({})).then(resp =>
        setTotalPending(resp?.payload?.totalPending),
      );
    }
  }, []);

  const handleRedirect = (path: string) => () => {
    if (path) navigate(path);
  };

  const mappedProgramList = programList
    .filter((prg: any) => {
      if (prg.children) {
        return find(prg.children, (child: any) =>
          includes(child.roles, user.role),
        );
      }
      if (!includes(prg.roles, user.role)) return false;
      return true;
    })
    .map(prg => {
      if (prg.children) {
        return {
          ...prg,
          children: prg.children.filter((child: any) => {
            let resChild = true;
            if (!includes(child.roles, user.role)) {
              resChild = false;
            }
            return resChild;
          }),
        };
      }
      return prg;
    });

  let program = find(mappedProgramList, program => {
    if (program.children) {
      return !!find(
        program.children,
        children =>
          location.pathname.includes(children.path) && children.path !== '/',
      );
    }
    return location.pathname.includes(program.path) && program.path !== '/';
  }) || { children: [], path: '', name: '', translationId: '' };
  if (location.pathname === '/') {
    program =
      find(mappedProgramList, program => program.path === '/') || program;
  }

  const selectedKeys = program.children
    ? `child-${program.name}-${indexOf(
        map(program.children, prg => prg.path),
        location.pathname,
      ).toString()}`
    : indexOf(
        map(mappedProgramList, prg => prg.path),
        program.path,
      ).toString();
  const openKeys = program.children
    ? `sub-${program.name}-${indexOf(
        map(mappedProgramList, prg => prg.name),
        program.name,
      ).toString()}`
    : '';
  const openKeysObj = openKeys ? { defaultOpenKeys: [openKeys] } : {};

  const renderSubMenuChildren = (prg: any) =>
    map(
      prg.children,
      (
        child: {
          parent: any;
          path: '';
          name: any;
          icon: any;
          translationId: '';
        },
        childIndex,
      ) => (
        <Menu.Item
          key={`child-${child.parent}-${childIndex}`}
          icon={child.icon}
          onClick={handleRedirect(child.path)}
        >
          {child.name === 'Verifications' ? (
            <Row style={{ display: 'flex' }}>
              {t(messages[child.translationId]) as string}
              <Badge count={totalPending} style={{ marginLeft: 5 }} />
            </Row>
          ) : (
            (t(messages[child.translationId]) as string)
          )}
        </Menu.Item>
      ),
    );

  const renderSubMenu = () =>
    map(
      mappedProgramList,
      (
        prg: {
          children: [];
          path: '';
          name: string;
          icon: any;
          translationId: '';
        },
        index,
      ) =>
        prg.children ? (
          <Menu.SubMenu
            key={`sub-${prg.name}-${index}`}
            icon={prg.icon}
            title={
              prg.name === 'Saldo' ? (
                <Row style={{ display: 'flex' }}>
                  {t(messages[prg.translationId]) as string}
                  <Badge count={totalPending} style={{ marginLeft: 5 }} />
                </Row>
              ) : (
                (t(messages[prg.translationId]) as string)
              )
            }
          >
            {renderSubMenuChildren(prg)}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={index}
            icon={prg.icon}
            onClick={handleRedirect(prg.path)}
          >
            {t(messages[prg.translationId]) as string}
          </Menu.Item>
        ),
    );

  return (
    <>
      {showSidebar && (
        <StyledLayout>
          <StyledSider trigger={null} width="100%">
            <div className="logo">
              <Row justify="center" align="top">
                <Space size={12}>
                  <img src={Logo} alt="logo" />
                  <MenuTitle>
                    <div>Pondok Pesantren</div>
                    <div>Al-Hidayah Karangsuci</div>
                    <div>Purwokerto</div>
                  </MenuTitle>
                </Space>
              </Row>
            </div>

            <StyledDiv>
              <Menu
                mode="inline"
                selectedKeys={[selectedKeys]}
                {...openKeysObj}
              >
                {renderSubMenu()}
              </Menu>
            </StyledDiv>

            {/* <div
                            style={{
                                marginLeft: '24px',
                                position: 'absolute',
                                bottom: '50px',
                            }}
                        >
                            <StyledLink
                                href="https://app.gitbook.com/@m-faiznoeris/s/al-hidayah-dashboard"
                                target="_blank"
                            >
                                <AntdCustomIcon
                                    component={MdHelpOutline}
                                    style={{
                                        minWidth: '14px',
                                        marginRight: '10px',
                                    }}
                                />
                                Help
                            </StyledLink>
                        </div> */}
          </StyledSider>
        </StyledLayout>
      )}
    </>
  );
};

export default Sidebar;
